/** @format */
import img from "../src/images/bg2.webp";
import joe from "../src/images/joe.webp";

const About = () => {
  const handleClick = () => {
    const section = document.getElementById("mySection");
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (<>
 
    <div className="about-us">
      <div className="about-us-inner">
        <div className="about-top">
          <h2>
            {" "}
            IF YOU HAVE THE DRIVE, WE CAN SHOW YOU HOW. LEARN HOW TO INVEST IN SINGLE-FAMILY AND MULTI-FAMILY PROPERTIES TODAY.
          </h2>{" "}
          <img src={img} alt="logo" />
        </div>
        <div className="about-mid">
          <h2>
          We have been helping people for years to shorten their learning curve by learning from our experience.
          </h2>
          <div>
            <p>
            Many successful real estate investors started out exactly where you are now - with a desire to invest in real estate, but are weighed down by a fear of not knowing where or how to start.
            </p>
            <p></p>
            <p>
            At RealSuccess Education, we’ve spent our time and energy researching, learning, investing and developing tools and systems that are proven to lead you on a successful path to invest in real estate.
            </p>
            <p></p>
            <p>
            The sky's the limit, the best part about being a real estate investor is there is NO ceiling on what someone can earn, no boss, or traffic to fight, no punching the time clock, we are only limited by what we do not know!
            </p>
            <p></p>
            <p>
            Why not you? All you have to do is Register today. Let us help you with the rest.

            </p>
          </div>
          <button onClick={handleClick}>Register NOw</button>
        </div>

        <div className="about-bottom">
     
          <div className="text-section about-bottom-sec">
            <img src={joe} alt="joe" />
            <h3>ABOUT JOE ARIAS</h3>
            <h4>Real-Estate Investor, Author, Entrepreneur, Educator</h4>
            <div>
              <p>
                I came to this country when I was 20 years old. As a Latino
                immigrant, I did not know the language, had no money, no
                contacts or special skills. I was all alone, not sure what I
                should do.
              </p>
              <p>
                I started working as a photographer. After a few years of
                struggling, I wasn’t getting ahead financially like I planned, I
                knew I needed to do something different, so I decided it was
                time to make a change. To learn how to not work for money, but
                have money work for me.
              </p>
              <p>
                After doing so much research, I found that more millionaires
                were made every year investing in real estate than any other
                field and I wanted that for myself. So, I got into real estate
                investing. I started taking classes, reading books, going to
                events, networking, grinding every day, doing things the hard
                way, trial and error, to build a business I could be proud of.
              </p>
              <p>
                <strong>
                  Since I started investing, I developed a system, a process for
                  getting deals done faster, easier then I had been. Over the
                  last 10+ years my partners and I have flipped HUNDREDS of
                  properties and own HUNDREDS of turn key rentals and
                  multi-family properties. We have completed over $200 million
                  in real estate investment transactions.
                </strong>
              </p>
              <p>
                I developed cutting-edge systems to simplify and scale the
                investing process. These systems can easily be applied to
                flipping, wholesaling, single-family and multi-family rentals,
                and other passive income strategies.
              </p>
              <p>
                Years ago, I founded a real estate investing education company
                called RealSuccess Investments. Which has allowed me to share my
                tools, knowledge, experience and systems with hundreds of
                up-and-coming investors.
              </p>
              <p>
                RealSuccess is focused on educating investors on flipping,
                wholesaling, and acquiring single-family and multi-family
                rentals.
              </p>
              <p>
                <strong>
                  To date, my team and I have trained thousands of students to
                  flip properties and to acquire single and multi-family
                  properties, so they can avoid the same trial and errors I went
                  through and shorten their learning curve to build a business
                  they love!
                </strong>
              </p>
            </div>
          </div>
        </div>
        <button onClick={handleClick}>Register NOw</button>
      </div>
    </div>
    </>
  );
};

export default About;
