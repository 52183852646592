import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import Header from "./Header";
import Calendar from "./Calendar";
import loading from "../src/images/loading.gif";
import Footer from "./Footer";
import ChooseUs from "./ChooseUs";
import About from "./About";

function Home() {
  const [sheetData, setSheetData] = useState(null);
  const [innerData, setInnerData] = useState();
  const [activeKey, setActiveKey] = useState();
  const [firstChild, setFirstChild] = useState();
  const [selectChild, setSelectChild] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const location = urlParams.get("loc");
    setSelectChild(location === null ? 0 : location );
    // setSelectChild(location);
    console.log("location", location)
    console.log("selectChild", selectChild)
  }, [selectChild]);



  useEffect(() => {
    axios
      .get("https://lp.taxliensuccess.com/sheetData")
      .then((response) => {
        let a = [];
        for (const [key, value] of Object.entries(response.data)) {
          a.push({
            key: key,
            value: value,
          });
        }
        setSheetData(a);

        if (selectChild >= a.length) {
          setActiveKey(a[0].key);
          setFirstChild(a[0].key);
          setInnerData(a[0]);
        } else {
          setActiveKey(a[selectChild].key);
          setFirstChild(a[selectChild].key);
          setInnerData(a[selectChild]);
        }


        // setActiveKey(a[selectChild].key);
        // setFirstChild(a[selectChild].key);
        // setInnerData(a[selectChild]);
    console.log("setFirstChild", a[selectChild].key)
    console.log("setInnerData", a[selectChild])
      })
      .catch((error) => console.error(error));
  }, [selectChild]);
  useEffect(() => {
    if (!sheetData) {
      const timeout = setTimeout(() => {
      
        window.location.reload(); // Refresh the page
      }, 10000);

      return () => {
        clearTimeout(timeout); // Clear the timeout when the component unmounts
      };
    }
  }, [sheetData]);
  const handleCheck = (item) => {
    setInnerData((prevInnerData) => {
      return { ...prevInnerData, ...item };
    });
    
 
    setFirstChild(item.key);
    setActiveKey(item.key);
    const section = document.getElementById("mySection");
    section.scrollIntoView({ behavior: "smooth" });
  };
  const handleCheckboxChange = (item) => {
    // console.log("item 2", item);
  };
  return (
    <div>
     
      <div>
        <Header />
      </div>

      {sheetData?.length > 0 ? (
        <section className="section-one">
          <div className="container ">
            <div className="top-heading">
              <h1>
                Register for the free live <br />
                2-Hour Class
              </h1>
              <p>Take control of your Financial Goals</p>
            </div>
            <div className="locations-box">
              <div className="loaction-box card">
                <div className="location-check">
                  <h2>
                    <span>1</span>Choose Your location
                  </h2>
                  <Form>
                    <div>
                      <div className="row">
                        {sheetData.length > 0 ? (
                          sheetData.map((item, index) => (
                            <div key={index}>
                              <div className="col-md-12">
                                <Form.Check
                                  onClick={() => handleCheck(item)}
                                  className={
                                    activeKey === item.key ? "active" : ""
                                  }
                                  inline
                                  label={item.key}
                                  name="group1"
                                  type="radio"
                                  checked={item.key === firstChild}
                                  onChange={handleCheckboxChange}
                                  id={index + 1}
                                />
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>Loading...</p>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>

              {innerData ? (
                <div className="loaction-box  card" id="mySection">
                  <div className=" location-inner ">
                    <h2>
                      <span>2</span>Choose your Class
                    </h2>
                    {innerData?.value?.map((inneritem, index) => (
                      <Calendar
                        key={index}
                        cityName={innerData}
                        alldata={inneritem}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      ) : (
        <div className="loading">
          <img src={loading} alt="loading" />
        </div>
      )}
      <div className="choose-us">
        <ChooseUs />
      </div>
      <About />
      <Footer />
    </div>
  );
}

export default Home;
